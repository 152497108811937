import React from "react"
import styled from "styled-components"
import { Map } from "@styled-icons/boxicons-regular/Map"
import { Facebook } from "@styled-icons/boxicons-logos/Facebook"
import { Instagram } from "@styled-icons/boxicons-logos/Instagram"

const Social = styled.div`
  display: flex;
  justify-content: center;

  color: ${({ theme }) => theme.colors.dimgray};

  a {
    padding: 0 8px;
    border-right: 1px solid ${({ theme }) => theme.borderColor.black08};
  }

  a:last-child {
    border: none;
  }
`

const IconFacebook = styled(Facebook)`
  padding: 8px;

  &:hover {
    background: #3b5998;
    color: ${props => props.theme.colors.white};
    border-radius: 50%;
  }
`

const IconInstagram = styled(Instagram)`
  padding: 8px;

  &:hover {
    background: #d6249f;
    background: radial-gradient(
      circle at 30% 107%,
      #fdf497 0%,
      #fdf497 5%,
      #fd5949 45%,
      #d6249f 60%,
      #285aeb 90%
    );
    color: ${props => props.theme.colors.white};
    border-radius: 50%;
  }
`

const IconMap = styled(Map)`
  padding: 8px;

  &:hover {
    background: #dd4b39;
    color: ${props => props.theme.colors.white};
    border-radius: 50%;
  }
`

const SocialGroupIcons = () => (
  <Social>
    <a
      href="https://www.facebook.com/nevaco.tudoemaco"
      aria-label="Visitar página no Facebook"
    >
      <IconFacebook size={20} />
    </a>

    <a
      href="https://www.instagram.com/nevacotudoemaco/"
      aria-label="Visitar página no Instagram"
    >
      <IconInstagram size={20} />
    </a>

    <a
      href="https://goo.gl/maps/LRjDmi6f6PidxN7R8"
      aria-label="Visitar local no Google Maps"
    >
      <IconMap size={20} />
    </a>
  </Social>
)

export default SocialGroupIcons
