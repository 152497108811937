// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-armarios-estantes-js": () => import("./../../../src/pages/armarios-estantes.js" /* webpackChunkName: "component---src-pages-armarios-estantes-js" */),
  "component---src-pages-armarios-roupeiros-js": () => import("./../../../src/pages/armarios-roupeiros.js" /* webpackChunkName: "component---src-pages-armarios-roupeiros-js" */),
  "component---src-pages-armarios-vitrines-js": () => import("./../../../src/pages/armarios-vitrines.js" /* webpackChunkName: "component---src-pages-armarios-vitrines-js" */),
  "component---src-pages-arquivos-js": () => import("./../../../src/pages/arquivos.js" /* webpackChunkName: "component---src-pages-arquivos-js" */),
  "component---src-pages-bancadas-js": () => import("./../../../src/pages/bancadas.js" /* webpackChunkName: "component---src-pages-bancadas-js" */),
  "component---src-pages-estantes-de-aco-js": () => import("./../../../src/pages/estantes-de-aco.js" /* webpackChunkName: "component---src-pages-estantes-de-aco-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mesas-js": () => import("./../../../src/pages/mesas.js" /* webpackChunkName: "component---src-pages-mesas-js" */)
}

